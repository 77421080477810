<template>
  <div style="position: relative; width: 100%; height: 100%">
    <div id="container"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
        marker:[]
    };
  },
  mounted() {
      
      let optionData = decodeURIComponent(window.location.href.split('=')[1]);

       this.marker = JSON.parse(optionData);
       setTimeout(() => {
           this.mapFun();
       }, 100);
       
  },
  
  methods: {
    mapFun() {
      let that = this;
      let markerList = [];
      let map = new AMap.Map("container", {
        zoom: 16, //级别
        center: [104.955347, 25.786404], //中心点坐标
      });
      
      console.log('this.marker',this.marker)

      for (let i in this.marker) {
        let item = this.marker[i];

        let marker = new AMap.Marker({
          position: new AMap.LngLat(Number(item[0]), Number(item[1])),
          offset: new AMap.Pixel(-10, -10),
          title: i,
          label: {
            offset: new AMap.Pixel(0, 20), //设置文本标注偏移量
            content: `${i}泊位数：${item[2]}<br/>使用中：${item[3]}<br/>空闲中：${item[4]}`, //设置文本标注内容
            direction: "top", //设置文本标注方位
          },
          width: 30,
          height: 30,
        });

        markerList.push(marker);
      }

      map.add(markerList);
    },
  },
};
</script>

<style lang="scss" scoped>
#container {
  width: 100%;
  height: 100vh;
  
  z-index: 1111;
  border-radius: 10px;
}
.amap-marker-label {
  // background-color: rgba(0,0,0,.5);
  // color:#333;
  // border:0px;
  position: absolute;
  z-index: 2;
  border: 1px solid #ccc;
  background-color: white;
  white-space: nowrap;
  cursor: default;
  padding: 3px;
  font-size: 12px;
  line-height: 14px;
}
</style>